import { useMemo } from "react";
import { default as ReactMarkdown } from "react-markdown";

const ChatbotChatCard = ({ chatbotUsername, chatbotChat }) => {
  const customComponents = useMemo(() => {
    return {
      h1: "b",
      h2: "b",
      h3: "b",
      h4: "b",
      h5: "b",
      h6: "b",
      p: ({ node, ...props }) => (
        <span {...props} className="!text-inherit leading-[inherit]"></span>
      ),
      strong: ({ node, ...props }) => (
        <b className="!text-inherit leading-[inherit]" {...props}></b>
      ),
      a: ({ node, href, children, ...props }) => (
        <a
          href={href}
          {...props}
          className="text-blue-600 font-medium hover:underline">
          {children}
        </a>
      ),
    };
  }, []);

  return (
    <div
      className={`font-inter rounded-lg p-3 border-[1px] border-[#C6C6C6] mb-3 ${
        chatbotUsername === "Your Message"
          ? "bg-[#393939] text-white"
          : "bg-white"
      }`}>
      <div className="font-inter font-bold pb-2">{chatbotUsername}</div>
      <ReactMarkdown components={customComponents}>{chatbotChat}</ReactMarkdown>
    </div>
  );
};

export default ChatbotChatCard;

import { memo, useEffect, useState } from "react";
import { FaAngleLeft, FaAngleRight } from "react-icons/fa";

const Pagination = ({
  totalResults,
  pageCount: pageCountProp,
  limit,
  page,
  onPageChange,
  customClassName = "",
}) => {
  const [currentPage, setCurrentPage] = useState(Number(page));
  const [prevTotalResults, setPrevTotalResults] = useState(0);
  const pageCount = pageCountProp ?? Math.ceil(totalResults / limit);

  useEffect(() => {
    if (page !== currentPage) {
      setCurrentPage(Number(page));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  useEffect(() => {
    if (totalResults && totalResults !== prevTotalResults) {
      setPrevTotalResults(totalResults);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalResults]);

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage((currPage) => {
        const latestPage = currPage - 1;
        onPageChange(latestPage);
        return latestPage;
      });
    }
  };
  const handleNext = () => {
    if (currentPage >= 0 && currentPage < pageCount) {
      setCurrentPage((currPage) => {
        const latestPage = currPage + 1;
        onPageChange(latestPage);
        return latestPage;
      });
    }
  };

  return (
    <div
      className={`flex justify-end lg:justify-center items-center text-sm text-grey-80 gap-5 my-3 ${customClassName}`}>
      <div className="flex gap-2">
        <button
          type="button"
          className={`w-5 items-center h-5 !m-0 rounded-[3px] text-white ${
            currentPage <= 0 ? "" : "bg-black"
          }`}
          onClick={handlePrevious}
          disabled={currentPage <= 0}>
          <FaAngleLeft className="m-auto text-xs" />
        </button>
        <button
          type="button"
          className={`w-5 items-center h-5 !m-0 rounded-[3px] text-white ${
            currentPage === pageCount - 1 || !totalResults ? "" : "bg-black"
          }`}
          onClick={handleNext}
          disabled={currentPage === pageCount - 1 || !totalResults}>
          <FaAngleRight className="m-auto text-xs" />
        </button>
      </div>
    </div>
  );
};

export default memo(Pagination);

import { MdOutlineChatBubble } from "react-icons/md";

const ChatbotHeader = () => {
  return (
    <div className="bg-gradient-to-r from-[#5138BA] to-[#4064CA] text-white rounded-tl-lg rounded-tr-lg px-6 py-[18px] font-inter font-bold flex items-center justify-between">
      <div className="flex justify-center items-center font-inter font-bold">
        <MdOutlineChatBubble size={24} className="mr-2" />
        CHAT WITH NIA
      </div>
    </div>
  );
};

export default ChatbotHeader;

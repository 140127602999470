import { userRefreshToken } from "../auth";
import { API } from "../backend";
import { getDataFromLocalstorage, setDataToLocalstorage } from "../common";

export const chat = async (message) => {
  const accessToken = getDataFromLocalstorage("quilt-chatbot").access_token;
  const localstorageData = getDataFromLocalstorage("quilt-chatbot") ?? {};
  if (accessToken) {
    const chatFetch = await fetch(`${API}/v1/chat/`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ message: message }),
    });
    const chatData = await chatFetch.json();
    if (chatData?.status_code === 401 && localstorageData?.refresh_token) {
      const signinRefreshToken = await userRefreshToken(
        localstorageData?.refresh_token
      );
      setDataToLocalstorage("quilt-chatbot", {
        ...localstorageData,
        ...signinRefreshToken,
      });
    }
    return chatData;
  }
  return;
};

export const chatHistory = async () => {
  const localstorageData = getDataFromLocalstorage("quilt-chatbot") ?? {};
  if (localstorageData.access_token) {
    const chatHistoryFetch = await fetch(`${API}/v1/chat/`, {
      method: "GET",
      mode: "cors",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${localstorageData?.access_token}`,
      },
    });
    const chatHistoryData = await chatHistoryFetch.json();
    if (
      chatHistoryData?.status_code === 401 &&
      localstorageData?.refresh_token
    ) {
      const signinRefreshToken = await userRefreshToken(
        localstorageData?.refresh_token
      );
      setDataToLocalstorage("quilt-chatbot", {
        ...localstorageData,
        ...signinRefreshToken,
      });
      return;
    }
    return chatHistoryData;
  }
  return;
};

const SuggestedQueries = ({ setChatbotUserInput, handleOnClick }) => {
  const SUGGESTQ = [
    "What is a clinical trial?",
    "What are the symptoms of high blood pressure?",
    "I just got diagnosed with type 2 diabetes, what are my treatment options?",
    "How can I afford my medical treatments?",
  ];

  return (
    <div className="rounded-lg p-3 font-inter border-[1px] border-[#C6C6C6] mb-3 bg-white">
      <div className="font-inter font-bold mb-3">Try these questions</div>
      <div className="ml-3">
        <ul className="list-disc list-outside ml-3">
          {SUGGESTQ.map((suggestq, idx) => {
            return (
              <li
                className="hover:underline cursor-pointer"
                onClick={() => {
                  setChatbotUserInput(suggestq);
                  handleOnClick(suggestq);
                }}
                key={idx}>
                {suggestq}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SuggestedQueries;

import { useEffect, useState, useRef } from "react";
import ChatbotChatCard from "./Components/ChatbotChatCard";
import ChatbotHeader from "./Components/ChatbotHeader";
import ChatbotUserInputFooter from "./Components/ChatbotUserInputFooter";
import LoadingState from "./Components/LoadingState";
import { useAuth } from "./hooks/use-auth";
import { chat, chatHistory } from "./chat";
import PulsingDotAnimation from "./Components/PulsingDotAnimation";
import SuggestedQueries from "./Components/SuggestedQueries";
import Sources from "./Components/Sources";

const App = () => {
  const [chatbotUserInput, setChatbotUserInput] = useState("");
  const [chatbotChats, setChatbotChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const lastCardRef = useRef(null);

  useEffect(() => {
    (async () => {
      const chatHistoryData = await chatHistory();
      let tempChatHistoryData = [];
      if (chatHistoryData?.chat_history?.length) {
        tempChatHistoryData = chatHistoryData?.chat_history?.map((chats) => ({
          chatbotUserName: chats?.role === "system" ? "NIA" : "Your Message",
          chatbotUserChat: chats?.content,
          chatbotUserSources: chats?.sources,
        }));
      }
      setChatbotChats([...chatbotChats, ...tempChatHistoryData]);
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (lastCardRef.current) {
      lastCardRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    // eslint-disable-next-line
  }, [chatbotChats]);

  const { accessToken } = useAuth();

  const handleChats = async (suggestq) => {
    try {
      setLoading(true);
      const chatData = await chat(suggestq ? suggestq : chatbotUserInput);
      if (!chatData?.message) {
        setChatbotChats((prevItems) => {
          const newObject = {
            chatbotUserName: "NIA",
            chatbotUserChat: chatData?.content,
            chatbotUserSources: chatData?.sources,
          };
          return [...prevItems, newObject];
        });
      }
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleOnClick = async (suggestq) => {
    setChatbotUserInput("");

    setChatbotChats((prevItems) => {
      const newObject = {
        chatbotUserName: "Your Message",
        chatbotUserChat: suggestq ? suggestq : chatbotUserInput,
        chatbotUserSources: [],
      };
      return [...prevItems, newObject];
    });

    handleChats(suggestq);
  };

  const renderSources = (chatbotUserSources) => {
    return <Sources chatbotUserSources={chatbotUserSources ?? []} />;
  };

  const renderChatbotChatCards = () => {
    return (
      <>
        <ChatbotChatCard
          chatbotUsername="NIA"
          chatbotChat="Hi, how can I help you?"
        />
        <SuggestedQueries
          setChatbotUserInput={setChatbotUserInput}
          handleOnClick={handleOnClick}
        />
        {chatbotChats.map((chatbotChat, idx) => {
          const isLastCard = idx === chatbotChats.length - 1;
          const { chatbotUserName, chatbotUserChat, chatbotUserSources } =
            chatbotChat;
          return (
            <div key={idx} ref={isLastCard ? lastCardRef : null}>
              <ChatbotChatCard
                chatbotUsername={chatbotUserName}
                chatbotChat={chatbotUserChat}
              />
              {renderSources(chatbotUserSources)}
            </div>
          );
        })}
      </>
    );
  };

  const renderErrorState = () => {
    return (
      <div className="flex justify-center items-center text-base font-bold text-red-950">
        {error}
      </div>
    );
  };

  // <ChatbotChatCard
  //   chatbotUsername="Chatbot"
  //   chatbotChat="Have questions? To continue, please login to your account."
  // />;

  return (
    <div className="bg-[#C6C6C6] w-screen min-h-screen rounded-lg overflow-hidden">
      <header className="sticky top-0 left-0 right-0 z-10">
        <ChatbotHeader />
      </header>
      <main className="px-6 py-3 bg-[#C6C6C6] z-0 border-x-[1px] border-[#C6C6C6] h-[calc(100vh-163px)] overflow-auto">
        {accessToken ? (
          renderChatbotChatCards()
        ) : (
          <div className="flex justify-center items-center">
            <LoadingState />
          </div>
        )}
        {loading ? (
          <div className="flex justify-center items-center mb-3">
            <PulsingDotAnimation width={30} height={30} />
          </div>
        ) : (
          <></>
        )}
        {error ? renderErrorState() : <></>}
      </main>
      <footer className="fixed bottom-0 left-0 right-0 z-10">
        <ChatbotUserInputFooter
          chatbotUserInput={chatbotUserInput}
          setChatbotUserInput={setChatbotUserInput}
          chatbotChats={chatbotChats}
          handleOnClick={handleOnClick}
        />
      </footer>
    </div>
  );
};

export default App;

import { useMemo, useState } from "react";
import PillLinks from "./PillLinks";
import Pagination from "./Pagination";

const Sources = ({ chatbotUserSources }) => {
  const limit = 2;
  const totalResults = chatbotUserSources?.length ?? 0;
  const pageCount = Math.ceil(totalResults / limit);

  const [page, setPage] = useState(0);
  const SlicedData = useMemo(() => {
    const start = page * limit;
    const end = page * limit + limit;
    return chatbotUserSources?.slice(start, end);
  }, [chatbotUserSources, page]);

  const fetchPageData = (pageIndex) => {
    setPage(pageIndex);
  };

  return chatbotUserSources.length ? (
    <>
      <div
        className={`grid ${
          chatbotUserSources.length === 1 ? "grid-cols-1" : "grid-cols-2"
        } gap-2 max-w-full overflow-auto`}>
        {SlicedData.map((source, idx) => (
          <div key={idx}>
            <PillLinks source={source} />
          </div>
        ))}
      </div>
      {chatbotUserSources.length > 2 ? (
        <div className="flex justify-end">
          <Pagination
            totalResults={totalResults}
            pageCount={pageCount}
            limit={limit}
            page={page}
            onPageChange={fetchPageData}
          />
        </div>
      ) : (
        <div className="mb-3"></div>
      )}
    </>
  ) : (
    <></>
  );
};

export default Sources;

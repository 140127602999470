const PillLinks = ({ source }) => {
  return (
    <div className="h-full p-4 text-base rounded-lg bg-white border-[1px] border-[#C6C6C6] flex flex-col justify-between gap-3">
      <div className="font-inter font-bold">
        <p className="font-inter font-bold">{source.title}</p>
      </div>
      <div>
        <a
          className="px-4 py-2 overflow-hidden truncate line-clamp-1 w-full inline-block text-white bg-black rounded-lg font-inter font-bold text-center"
          href={source.url}
          target="_blank"
          rel="noreferrer">
          View
        </a>
      </div>
    </div>
  );
};

export default PillLinks;

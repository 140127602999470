import { API } from "../backend";

export const signin = async (authToken) => {
  const signinFetch = await fetch(`${API}/v1/auth/token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ token: authToken }),
  });
  const signinData = await signinFetch.json();
  return signinData;
};

export const userRefreshToken = async (refreshToken) => {
  const userRefreshTokenFetch = await fetch(`${API}/v1/auth/refresh-token`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ refresh_token: refreshToken }),
  });
  const userRefreshTokenData = await userRefreshTokenFetch.json();
  if (
    userRefreshTokenData?.status_code === 401 ||
    userRefreshTokenData?.message
  ) {
    localStorage.clear();
  } else {
    return userRefreshTokenData;
  }
  return;
};

import { useLottie } from "lottie-react";

import pulsingDotsAnimation from "../assets/lottieAnimations/pulsing-dots.json";

const PulsingDotAnimation = ({ size, width, height }) => {
  const style = {
    height: height ?? size,
    width: width ?? size,
  };

  const options = {
    animationData: pulsingDotsAnimation,
    loop: true,
    autoplay: true,
    rendererSettings: {
      progressiveLoad: true,
      preserveAspectRatio: "xMidYMid meet",
    },
  };

  const { View } = useLottie(options, style);

  return <div className="relative top-[5px]">{View}</div>;
};

export default PulsingDotAnimation;

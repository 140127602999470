import { useEffect, useState } from "react";
import { signin, userRefreshToken } from "../auth";
import { getDataFromLocalstorage, setDataToLocalstorage } from "../common";

export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const localstorageData = getDataFromLocalstorage("quilt-chatbot");
  useEffect(() => {
    const accessTokenFromLocalstorage =
      getDataFromLocalstorage("quilt-chatbot");
    setAccessToken(accessTokenFromLocalstorage?.access_token ?? null);
    setRefreshToken(accessTokenFromLocalstorage?.refresh_token ?? null);
  }, []);
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const clientToken = urlParams.get("token");
    setToken(clientToken);
    // eslint-disable-next-line
  }, []);
  const getTokens = async () => {
    if (token && !accessToken) {
      const signinData = await signin(token);
      if (signinData?.status_code === 401 && refreshToken) {
        const signinRefreshToken = await userRefreshToken(refreshToken);
        setAccessToken(signinRefreshToken?.access_token);
        setDataToLocalstorage("quilt-chatbot", {
          ...localstorageData,
          ...signinRefreshToken,
        });
        return;
      }
      setAccessToken(signinData?.access_token);
      setRefreshToken(signinData?.refresh_token);
      setDataToLocalstorage("quilt-chatbot", signinData);
    }
  };
  useEffect(() => {
    if (token) {
      getTokens();
    }
    // eslint-disable-next-line
  }, [token]);
  useEffect(() => {
    getTokens();
    // eslint-disable-next-line
  }, []);
  return { accessToken, refreshToken };
};

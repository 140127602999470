import { MdOutlineSend } from "react-icons/md";
import { getDataFromLocalstorage } from "../common";

const ChatbotUserInputFooter = ({
  chatbotUserInput,
  setChatbotUserInput,
  handleOnClick,
}) => {
  const localstorageData = getDataFromLocalstorage("quilt-chatbot");

  const handleOnChange = (e) => {
    setChatbotUserInput(e.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleOnClick();
    }
  };

  return (
    <div className="w-full rounded-md bg-gray-100 border-[1px] border-[#C6C6C6]">
      <textarea
        value={chatbotUserInput}
        onChange={handleOnChange}
        onKeyDown={handleKeyDown}
        placeholder="Ask anything about medical terms, conditions, or any other related topics."
        className="w-full rounded-tr-md rounded-tl-md p-3 resize-none outline-none block border-b-[1px] border-[#C6C6C6] font-inter"
        disabled={!localstorageData?.access_token}></textarea>
      <div className="flex items-center justify-end py-1 px-2 bg-gray-100">
        <button
          onClick={() => handleOnClick()}
          className={`rounded-md p-2 ${
            chatbotUserInput === ""
              ? "bg-zinc-300 text-zinc-500"
              : "text-white bg-black"
          }`}
          disabled={chatbotUserInput === ""}>
          <MdOutlineSend size={16} />
        </button>
      </div>
    </div>
  );
};

export default ChatbotUserInputFooter;
